<template>
  <div class="soul">
    <nav class="level is-mobile pt-4 px-4 teal-bg">
      <span class="level-item has-text-centered">
        <a class="link is-info" v-scroll-to="'#home-hero'">Inicio</a>
      </span>
      <span class="level-item has-text-centered">
        <a class="link is-info" v-scroll-to="'#aboutus'">Nosotros</a>
      </span>
      <span class="level-item has-text-centered">
        <a class="link is-info" v-scroll-to="'#benefits'">Beneficios</a>
      </span>
      <span class="level-item has-text-centered">
        <a class="link is-info" v-scroll-to="'#contact'">Contacto</a>
      </span>
    </nav>

    <section id="home-hero">
      <div class="caption">
        <img src="../assets/soul_logo.png" alt="SOUL STUDIOS" />
      </div>
    </section>

    <section id="aboutus">
      <div class="columns is-multiline is-gapless has-text-centered">
        <div class="column is-6">
          <div class="welcome-text-box p-4">
            <h1 class="py-1">¡Bienvenida!</h1>
            <p>
              Somos SOUL COMPANY, un estudio de modelaje webcam ubicado en el
              eje cafetero, nacido en julio de 2018, avalado por Grupo Bedoya,
              la empresa pionera y con mayor trayectoria de la industria en
              Colombia. Nos caracterizamos por ser una compañía en la que priman
              el amor, el compañerismo, la constancia y la disciplina. Estamos
              integrados por un equipo profesional, con metas claras, enfocado
              en el perfilamiento de modelos como profesionales integrales y
              sobresalientes.
            </p>
          </div>
        </div>
        <div class="column is-half">
          <div id="welcome-left"></div>
        </div>
      </div>
    </section>

    <section id="benefits" class="has-text-centered">
      <div class="columns is-gapless is-multiline">
        <div class="column is-half">
          <div class="welcome-text-box p-4">
            <h1>Beneficios</h1>
            <div class="py-3">
              <p>
                Este es el momento perfecto para empezar a cumplir tus sueños, y
                como en Soul Company pensamos en tu bienestar te presentamos
                nuestro Club de Beneficios. Únete a nosotros como una Estrella
                Soul y disfruta de enormes descuentos en las empresas más
                importantes del Eje Cafetero, especializadas en cuidado
                personal, adquisición de vehículos, tratamientos estéticos,
                maquillaje, accesorios, lencería y preciosos juguetes sexuales
              </p>
            </div>
          </div>
        </div>
        <div class="column is-half">
          <div id="welcome-right"></div>
        </div>
      </div>
    </section>

    <section id="rooms">
      <div class="columns is-vcentered has-text-centered">
        <div class="column">
          <div class="amenities p-4">
            <h1>Nuestras instalaciones</h1>
            <p>
              Hemos diseñado espacios a la medida, pensados en la <br />
              comodidad de nuestras modelos.
            </p>
          </div>
        </div>
      </div>

      <div class="columns is-multiline is-gapless is-vcentered is-mobile">
        <div class="column is-6">
          <div id="room16"></div>
        </div>
        <div class="column is-6">
          <div id="room13"></div>
        </div>
        <div class="column is-6">
          <div id="room12"></div>
        </div>
        <div class="column is-6">
          <div id="room18"></div>
        </div>
        <div class="column is-12">
          <div id="room15"></div>
        </div>
      </div>
    </section>

    <section id="contact" class="section has-text-centered">
      <div class="columns">
        <div class="column is-12">
          <div class="column is-12">
            <div class="columns is-multiline is-mobile">
              <div class="column is-12">
                <div class="columns is-vcentered is-mobile">
                  <div class="column">
                    <h1>Contacto</h1>
                  </div>
                  <div class="column">
                    <a
                      href="https://www.instagram.com/soulcompany1"
                      target="_blank"
                    >
                      <i class="fab fa-instagram"></i>
                    </a>
                    <a
                      href="https://www.facebook.com/Soulcompany-247973352651475"
                      target="_blank"
                      class="mx-4"
                    >
                      <i class="fab fa-facebook-f"></i>
                    </a>
                    <a href="https://twitter.com/SoulCompany1" target="_blank">
                      <i class="fab fa-twitter"></i>
                    </a>
                  </div>
                </div>
              </div>
              <div class="column is-6">
                Soul Comany VIP: <br />
                <address>Carrera 9 # 13N-69</address>
              </div>
              <div class="column is-6">
                Soul Company: <br />
                <address>Calle 16N #12-41</address>
              </div>
              <div class="column is-12">
                <p>Deseas hacer parte de Soul Company?</p>
                <p>Escribenos:</p>
              </div>
              <div class="column is-12">
                <p>contacto@soulstudioscolombia.com</p>
                <p>TELÉFONO Y WHATSAPP: 311 787 0121</p>
              </div>
            </div>
          </div>

          <div class="column is-12 pt-6 mt-5">
            <form>
              <b-field expanded>
                <b-input
                  placeholder="Nombre *"
                  v-model="formData.name"
                ></b-input>
              </b-field>
              <b-field expanded>
                <b-input
                  placeholder="Email *"
                  v-model="formData.email"
                ></b-input>
              </b-field>
              <b-field>
                <b-input
                  v-model="formData.message"
                  class="has-fixed-size"
                  rows="4"
                  placeholder="Mensaje *"
                  maxlength="500"
                  type="textarea"
                ></b-input>
              </b-field>
            </form>
            <b-button class="teal-btn" expanded @click.prevent="sendForm()"
              ><i class="fas fa-paper-plane"></i> Enviar mensaje</b-button
            >
          </div>
        </div>

        <hr>

        <div class="column is-12">
          <div id="contact-bg"></div>
          <div id="footer" class="has-text-centered">
            <p>&copy; 2020 by Trend Studios for Soul company</p>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "Mobile",
  title: "Soul Company",
  components: {},
  data() {
    return {
      formData: {},
    };
  },

  methods: {
    sendForm() {
      if (
        this.formData.name === null ||
        this.formData.name === "" ||
        this.formData.email === null ||
        this.formData.email === "" ||
        this.formData.message === null ||
        this.formData.message === ""
      ) {
        this.$swal({
          icon: "warning",
          title: "¡Atención!",
          text: "Todos los campos son requeridos",
        });

        return;
      }

      axios
        .post("/api/contact.php", this.formData)
        .then((e) => {
          if (e.data.success) {
            this.formData.name = null;
            this.formData.email = null;
            this.formData.message = null;

            this.$swal({
              icon: "success",
              title: "¡Correcto!",
              text: "¡Mensaje enviado exitosamente!",
              timer: 8000,
            });
          } else {
            this.$swal({
              icon: "error",
              text: "No se ha podido enviar el mensaje. Intente mas tarde.",
            });
          }
        })
        .catch(() => {
          this.$swal({
            icon: "error",
            text: "No se ha podido enviar el mensaje. Intente mas tarde.",
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.tabs:not(:last-child),
.pagination:not(:last-child),
.message:not(:last-child),
.level:not(:last-child),
.breadcrumb:not(:last-child),
.highlight:not(:last-child),
.block:not(:last-child),
.title:not(:last-child),
.subtitle:not(:last-child),
.table-container:not(:last-child),
.table:not(:last-child),
.progress:not(:last-child),
.progress-wrapper.is-not-native:not(:last-child),
.notification:not(:last-child),
.content:not(:last-child),
.box:not(:last-child) {
  padding-bottom: 1.5rem;
  margin-bottom: 0 !important;
}

#home-hero {
  background-image: url("../assets/homeHero.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  height: 300px;
  background-position: center center;
  background-color: #606060;
  position: relative;
}

.caption {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  width: 300px;
  height: 146px;
}

#welcome-left {
  background-image: url("../assets/welcome.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  height: 400px;
  background-position: center center;
  position: relative;
}

#welcome-right {
  background-image: url("../assets/welcomeDos.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  height: 400px;
  background-position: center center;
  position: relative;
}

#rooms {
  background-color: #373433;
  height: 100%;
}

#room16 {
  background-image: url("../assets/room16.jpg");
  background-size: 250px 200px;
  background-repeat: no-repeat;
  height: 200px;
  background-position: center center;
  position: relative;

  &:hover {
    transform: scale(1.1);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
    -moz-osx-font-smoothing: grayscale;
    transition-duration: 0.3s;
    transition-property: transform;
  }
}

#room13 {
  background-image: url("../assets/room13.jpg");
  background-size: 250px 200px;
  background-repeat: no-repeat;
  height: 200px;
  background-position: center center;
  position: relative;

  &:hover {
    transform: scale(1.1);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
    -moz-osx-font-smoothing: grayscale;
    transition-duration: 0.3s;
    transition-property: transform;
  }
}

#room12 {
  background-image: url("../assets/room12.jpg");
  background-size: 250px 200px;
  background-repeat: no-repeat;
  height: 200px;
  background-position: center center;
  position: relative;

  &:hover {
    transform: scale(1.1);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
    -moz-osx-font-smoothing: grayscale;
    transition-duration: 0.3s;
    transition-property: transform;
  }
}

#room18 {
  background-image: url("../assets/room18.jpg");
  background-size: 250px 200px;
  background-repeat: no-repeat;
  height: 200px;
  background-position: center center;
  position: relative;

  &:hover {
    transform: scale(1.1);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
    -moz-osx-font-smoothing: grayscale;
    transition-duration: 0.3s;
    transition-property: transform;
  }
}

#room15 {
  background-image: url("../assets/room15.jpg");
  background-size: 400px 200px;
  background-repeat: no-repeat;
  height: 200px;
  background-position: center center;
  position: relative;

  &:hover {
    transform: scale(1.1);
    box-shadow: 0 0 1px rgba(0, 0, 0, 0);
    -moz-osx-font-smoothing: grayscale;
    transition-duration: 0.3s;
    transition-property: transform;
  }
}

.amenities {
  text-align: center;
  padding: 90px;

  p {
    color: #fff;
  }

  h1 {
    color: #fff;
  }
}

p {
  color: #606060;
  font-family: "Raleway", sans-serif;
  font-size: 12px !important;
}

h1 {
  font-size: 25px;
}
</style>
