import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'

import Buefy from 'buefy'
import 'buefy/dist/buefy.css'
Vue.use(Buefy)

import VueMobileDetection from 'vue-mobile-detection'
Vue.use(VueMobileDetection)

import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
Vue.use(VueSweetalert2);

Vue.config.productionTip = false

import './scss/app.scss'
import title from './mixins/title'
Vue.mixin(title);

import vueScrollto from "vue-scrollto";
Vue.use(vueScrollto);

import "@fortawesome/fontawesome-free/css/all.css";
import "@fortawesome/fontawesome-free/js/all.js";
import "hover.css";

window.axios = require("axios");

import Scrollspy from 'vue2-scrollspy';
Vue.use(Scrollspy);

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
